import React, { createContext, useContext, useRef, useState } from "react";
import Turnstile from "react-turnstile";

const CaptchaContext = createContext();

export const CaptchaContextProvider = ({ children }) => {
  const [captchaToken, setCaptchaToken] = useState("");
  const [turnstileBound, setTurnstileBound] = useState();
  const [captchaError, setCaptchaError] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const captchaRef = useRef();

  const resetCaptcha = () => {
    if (turnstileBound && turnstileBound.reset) {
      turnstileBound.reset();
      setIsCaptchaValid(false);
      setCaptchaToken("");
      setCaptchaError("");
    }
  };

  const handleVerify = (token) => {
    setCaptchaToken(token);
    setIsCaptchaValid(true);
    setCaptchaError("");
  };

  const handleError = (err) => {
    setCaptchaError("Captcha failed: " + err);
    setTimeout(resetCaptcha, 2000);
  };

  const clearCaptchaErrorsAndToken = () => {
    setCaptchaError("");
    setCaptchaToken("");
    setIsCaptchaValid(false);
  };

  const getTurnstileComponent = () => (
    <div className="flex">
      <Turnstile
        ref={captchaRef}
        sitekey="0x4AAAAAAAkUXk9knx9JpQKp"
        theme="dark"
        onLoad={(_, bound) => setTurnstileBound(bound)}
        onVerify={handleVerify}
        onError={handleError}
      />
    </div>
  );

  const context = {
    getTurnstileComponent,
    captchaToken,
    clearCaptchaErrorsAndToken,
    isCaptchaValid,
    captchaError,
    resetCaptcha,
  };

  return (
    <CaptchaContext.Provider value={context}>
      {children}
    </CaptchaContext.Provider>
  );
};

export const useCaptcha = () => useContext(CaptchaContext);
