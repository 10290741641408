import PurchaseHistory from "../components/PurchaseHistory";
import AccountSettings from "../components/Account/Settings";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { withAuth } from "../utils/withAuth";

const Account = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggleState, setToggleState] = useState("account");
  const { logout } = useAuth();

  const toggleTab = (index) => {
    setToggleState(index);
    if (index === "account") {
      setSearchParams({ tab: index });
    } else if (index === "history") {
      setSearchParams({ tab: index });
    }
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab === "account") {
      toggleTab("account");
    } else if (tab === "history") {
      toggleTab("history");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="container mx-auto"
    >
      <h1>
        My <span className="text-main">account</span>
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 mt-16 min-h-[500px]">
        <div className="flex flex-col justify-between md:border-r-4 border-dark-100 text-2xl h-full font-medium pr-2">
          <div className="flex flex-col gap-3">
            <button
              className={`${
                toggleState === "account"
                  ? "bg-dark-100"
                  : "hover:bg-dark-100/50"
              } rounded-xl lg:rounded-l-xl py-4 w-full duration-200`}
              onClick={() => toggleTab("account")}
            >
              Account Settings
            </button>
            <button
              className={`${
                toggleState === "history"
                  ? "bg-dark-100"
                  : "hover:bg-dark-100/50"
              } rounded-xl lg:rounded-l-xl py-4 w-full duration-200`}
              onClick={() => toggleTab("history")}
            >
              Purchase History
            </button>
            <button
              // MOBILE
              className="md:hidden rounded-xl lg:rounded-l-xl py-4 w-full hover:bg-red-400/20 duration-200 bg-red-500/20"
              onClick={() => {
                logout("/");
              }}
            >
              Log out
            </button>
          </div>
          <button
            // DESKTOP
            className="hidden md:inline-block rounded-xl lg:rounded-l-xl py-4 w-full hover:bg-red-400/20 duration-200 bg-red-500/20"
            onClick={() => {
              logout("/");
            }}
          >
            Log out
          </button>
        </div>
        <div className="md:col-span-2 lg:col-span-4 grid-cols-4 px-10 pt-10 md:pt-0">
          <AnimatePresence mode="wait">
            {(toggleState === "account" && <AccountSettings />) ||
              (toggleState === "history" && <PurchaseHistory />)}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};

export default withAuth(Account);
