export default function NotFound() {
  return (
    <div className="flex items-center justify-center px-10 py-56">
      <div className="text-center">
        <h1 className="mb-4 text-6xl font-bold text-white">404</h1>
        <p className="mb-8 text-xl text-neutral-600">
          The page you entered does not exist!
        </p>
        <a href="/" className="cta !px-6 !py-3">
          Go back to Home
        </a>
      </div>
    </div>
  );
}
