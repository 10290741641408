import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import useSWR from "swr";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const gameFetcher = (url) =>
  fetch(url, { credentials: "include" }).then((res) => res.json());

function Step2({ handleNext, handleBack, ID, purchaseDataChange }) {
  const [selected, setSelected] = useState(null);
  const [games, setGames] = useState([]);

  const handleSelect = (index) => {
    const { name, id, universeId } = games[index];
    purchaseDataChange("game", {
      id,
      universeId,
      name,
    });
    setSelected(index);
  };

  const { data, error, isLoading } = useSWR(
    ID
      ? `${process.env.REACT_APP_MAIN_PROTOCOL}://rbx.${process.env.REACT_APP_MAIN_DOMAIN}/get-games?id=` +
          ID
      : null,
    gameFetcher
  );

  useEffect(() => {
    if (data) setGames(data.Data);
  }, [data]);

  if (!isLoading && error)
    return (
      <div>
        <h1 className="text-lg md:text-xl tracking-wider">
          Error loading data
        </h1>
      </div>
    );

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <form>
        <h1 className="text-4xl font-semibold text-center md:text-start">
          Select one of your games
        </h1>
        <h2 className="text-lg text-white/75 pt-2 text-center md:text-start">
          You will need to create a gamepass on this game in the next step!
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-5 gap-5">
          {isLoading ? (
            <>
              <GameSkeleton />
              <GameSkeleton />
              <GameSkeleton />
            </>
          ) : (
            games.map((game, idx) => {
              return (
                <Game
                  {...game}
                  idx={idx}
                  selected={selected}
                  handleSelect={handleSelect}
                />
              );
            })
          )}
        </div>
        {!isLoading && !error && games.length === 0 && (
          <h2 className="text-xl text-red-500 pb-4">
            No games were found on this account.<br></br>Please make sure you
            have a game created and it is set to public.
          </h2>
        )}
        <div className="flex gap-4 justify-center md:justify-end">
          <button
            type="button"
            className="cta-cancel !py-2 w-32 text-xl text-center"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="button"
            className="cta !py-2 w-32 text-xl text-center"
            onClick={() => {
              if (selected === null)
                return toast.error("You must select a game!");
              handleNext();
            }}
          >
            Next
          </button>
        </div>
      </form>
    </motion.div>
  );
}

function Game({
  id,
  name,
  thumbnailUrl,
  universeId,
  idx,
  selected,
  handleSelect,
}) {
  return (
    <div
      // Custom properties
      gameName={name}
      universeId={universeId}
      gameId={id}
      className={`min-w-[225px] h-[250px] md:h-[300px] bg-dark rounded-2xl overflow-hidden cursor-pointer drop-shadow-xl ${
        selected === idx
          ? "ring-2 ring-offset-4 ring-offset-dark-100 ring-main"
          : ""
      }`}
      onClick={() => handleSelect(idx)}
    >
      <div>
        <h2 className="py-3 font-medium text-center border-b-2 border-white/5 text-xl">
          {name}
        </h2>
      </div>

      <div className="w-full h-full bg-white/10">
        <img
          src={thumbnailUrl}
          alt="game thumbnail"
          className="w-full h-full"
        />
      </div>
    </div>
  );
}

function GameSkeleton() {
  return (
    <div className="min-w-[225px] h-[250px] md:h-[300px] bg-dark rounded-2xl overflow-hidden cursor-pointer drop-shadow-xl">
      <div className="py-3 text-center">
        <Skeleton width={150} />
      </div>
      <div className="w-full h-full bg-white/10">
        <Skeleton className="w-full h-full" />
      </div>
    </div>
  );
}

export default Step2;
