import React, { useState } from "react";
import { useAuth } from "../../context/authContext";
import { useCaptcha } from "../../context/captchaContext";

export default function Register() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const { register, isRegisterLoading, registerError, registerMessage } =
    useAuth();
  const { captchaToken, isCaptchaValid, captchaError, getTurnstileComponent } =
    useCaptcha();

  async function sendRegistration() {
    await register(email, password, confirmPassword, captchaToken);
  }

  return (
    <div className="flex flex-col md:gap-5 gap-3">
      <div className="flex flex-col">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> E-mail
        </label>
        <input
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-base md:text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> Password
        </label>
        <input
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          className="!bg-dark rounded-md w-full !border-none placeholder-white/50 border-white/5 text-base md:text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> Confirm password
        </label>
        <div className="flex flex-col gap-1">
          <input
            type={showPassword ? "text" : "password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-base md:text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
          />
          <div className="flex">
            <button
              onClick={() => setShowPassword((state) => !state)}
              className="text-white hover:text-white/75 duration-200 pl-2"
            >
              {showPassword ? "Hide password" : "Show password"}
            </button>
          </div>
        </div>
      </div>
      {registerMessage && (
        <p className="text-main text-sm lg:text-base">{registerMessage}</p>
      )}
      {getTurnstileComponent()}
      <button
        disabled={!(isCaptchaValid && email && password && confirmPassword)}
        className="cta text-lg"
        onClick={sendRegistration}
      >
        Register
      </button>
      {isRegisterLoading && (
        <p className="text-white text-sm lg:text-base">Loading...</p>
      )}
      {registerError && (
        <p className="text-red-500 text-sm lg:text-base">{registerError}</p>
      )}
      {captchaError && (
        <p className="text-red-500 text-sm lg:text-base">{captchaError}</p>
      )}
    </div>
  );
}
