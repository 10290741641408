import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useCaptcha } from "../../context/captchaContext";
import { useAuth } from "../../context/authContext";
import { useSearchParams } from "react-router-dom";

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [token] = useState(() => {
    return searchParams.get("token");
  });
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [showPasswords, setShowPasswords] = useState(false);
  const { captchaToken, getTurnstileComponent, isCaptchaValid, captchaError } =
    useCaptcha();
  const {
    finalResetPassword,
    finalResetPasswordMessage,
    finalResetPasswordError,
    finalResetPasswordLoading,
  } = useAuth();

  async function sendFinalResetPassword() {
    await finalResetPassword(
      token,
      newPassword,
      confirmNewPassword,
      captchaToken
    );
  }

  useEffect(() => {
    const seeIfTokensValid = async () => {
      setIsLoading(true);
      try {
        const req = await fetch(
          `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/check-reset-pass-token?token=` +
            token
        );
        const data = await req.json();

        if (req.status !== 200) {
          setError(data.Message);
          setIsTokenValid(false);
          return;
        }

        setIsTokenValid(true);
        return;
      } catch (error) {
        setIsTokenValid(false);
        setError("Could not validate token");
      } finally {
        setIsLoading(false);
      }
    };
    seeIfTokensValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateNewPassword = (e) => setNewPassword(e.target.value);
  const updateConfirmNewPassword = (e) => setConfirmNewPassword(e.target.value);

  if (isLoading)
    return (
      <div className="flex justify-center md:py-48 py-20">
        <h1 className="text-lg md:text-xl tracking-widest">Loading...</h1>
      </div>
    );
  if (!isLoading && error)
    return (
      <div className="flex justify-center md:py-48 py-20">
        <h1 className="text-lg md:text-xl tracking-widest">{error}</h1>
      </div>
    );

  if (!isTokenValid) {
    return (
      <div className="flex justify-center md:py-48 py-20">
        <h1 className="text-lg md:text-xl tracking-widest">Invalid Token</h1>
      </div>
    );
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex justify-center"
      >
        <div className="flex flex-col gap-4 justify-center">
          <div className="formContent !mt-0">
            <h2 className="text-2xl md:text-4xl font-semibold text-center md:text-start">
              Reset Your Password
            </h2>
            <div className="flex flex-col gap-2 pt-4">
              <div className="flex flex-col">
                <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
                  <span className="text-red-500">*</span> New Password
                </label>
                <input
                  type={showPasswords ? "text" : "password"}
                  onChange={updateNewPassword}
                  className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-base md:text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
                  <span className="text-red-500">*</span> Confirm Password
                </label>
                <input
                  type={showPasswords ? "text" : "password"}
                  onChange={updateConfirmNewPassword}
                  className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-base md:text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
                />
              </div>
            </div>
            <div className="flex pb-4">
              <button
                onClick={() => setShowPasswords((state) => !state)}
                className="text-white hover:text-white/75 duration-200 pl-2"
              >
                {showPasswords ? "Hide passwords" : "Show passwords"}
              </button>
            </div>
            {getTurnstileComponent()}
            <div className="flex justify-end pt-2">
              <button
                type="button"
                className="cta md:text-lg"
                onClick={sendFinalResetPassword}
                disabled={
                  !(isCaptchaValid && newPassword && confirmNewPassword)
                }
              >
                Confirm
              </button>
            </div>

            {finalResetPasswordMessage && (
              <div>
                <p className="text-main text-sm lg:text-base">
                  {finalResetPasswordMessage}
                </p>
              </div>
            )}
            {finalResetPasswordLoading && (
              <div>
                <p className="text-white text-sm lg:text-base">Loading...</p>
              </div>
            )}
            {finalResetPasswordError && (
              <div>
                <p className="text-red-500 text-sm lg:text-base">
                  {finalResetPasswordError}
                </p>
              </div>
            )}
            {captchaError && (
              <div>
                <p className="text-red-500 text-sm lg:text-base">
                  {captchaError}
                </p>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default ResetPassword;
