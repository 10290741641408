import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  isValidEmail,
  validateRegistrationDetails,
} from "../utils/validateRegistration";
import { useAuthModal } from "./authModalContext";
import { useCaptcha } from "./captchaContext";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  // Login state
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  // Register state
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [registerMessage, setRegisterMessage] = useState(false);

  // Change password state
  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(false);

  // Reset password state
  const [isResetPasswordLoading, setIsResetPasswordLoading] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [resetPasswordMessage, setResetPasswordMessage] = useState(false);

  // The final reset password req
  const [finalResetPasswordMessage, setFinalResetPasswordMessage] = useState();
  const [finalResetPasswordError, setFinalResetPasswordError] = useState();
  const [finalResetPasswordLoading, setFinalResetPasswordLoading] = useState();

  // General auth state
  const [isSignedIn, setIsSignedIn] = useState(false);

  // Validate login state
  const [validateLoginLoading, setValidateLoginLoading] = useState(true);

  const { resetCaptcha } = useCaptcha();
  const { setAuthModalClose, setAuthModalOpen } = useAuthModal();
  const navigate = useNavigate();

  const login = async (email, password, captchaToken, ref = null) => {
    if (!captchaToken) return setLoginError("Please complete the captcha.");

    if (!email || !password)
      return setLoginError("Please enter your email and password.");

    try {
      setLoginError(false);
      setIsLoginLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "cf-turnstile-response": captchaToken,
          },
          credentials: "include",
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      const data = await res.json();
      setIsLoginLoading(false);
      if (res.status === 200) {
        setIsSignedIn(true);
        setLoginError(false);
        toast.success("Successfully logged in!");
        setTimeout(() => {
          setAuthModalClose();
          navigate("/buy");
        }, 1000);
      } else {
        resetCaptcha();
        setLoginError(data.Message);
      }
    } catch (error) {
      resetCaptcha();
      setLoginError("An unknown error occured.");
    } finally {
      setIsLoginLoading(false);
    }
  };

  const register = async (email, password, confirmPassword, captchaToken) => {
    try {
      if (!captchaToken)
        return setRegisterError("Please complete the captcha.");

      if (!email || !password || !confirmPassword)
        return setRegisterError("Please enter your registration details.");

      const validateDetails = validateRegistrationDetails(
        email,
        password,
        confirmPassword
      );
      if (validateDetails.result === false) {
        setRegisterError(validateDetails.message);
        return;
      }

      setRegisterError(false);
      setRegisterMessage(false);
      setIsRegisterLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "cf-turnstile-response": captchaToken,
          },
          body: JSON.stringify({
            email,
            password,
            confirmPassword,
          }),
        }
      );
      const data = await req.json();
      setIsRegisterLoading(false);
      if (req.status === 201) {
        setRegisterError(false);
        setRegisterMessage(data.Message);
      } else {
        resetCaptcha();
        setRegisterError(data.Message);
      }
    } catch (error) {
      setRegisterMessage(false);
      resetCaptcha();
      setRegisterError("An unknown error occured.");
    } finally {
      setIsRegisterLoading(false);
    }
  };

  const changePassword = async (
    newPassword,
    confirmNewPassword,
    captchaToken
  ) => {
    try {
      setIsChangePasswordLoading(true);
      setChangePasswordError(false);

      if (!newPassword || !confirmNewPassword) {
        setChangePasswordError("Please enter all information.");
        return;
      }

      if (!captchaToken) {
        setChangePasswordError("Please complete the captcha.");
        return;
      }

      if (newPassword !== confirmNewPassword) {
        setChangePasswordError("The passwords do not match!");
        return;
      }

      const validateDetails = validateRegistrationDetails(
        "valid@gmail.com",
        newPassword,
        confirmNewPassword
      );

      if (validateDetails.result === false) {
        setChangePasswordError(validateDetails.message);
        return;
      }

      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/change-password`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "cf-turnstile-response": captchaToken,
          },
          body: JSON.stringify({
            newPassword,
            confirmNewPassword,
          }),
        }
      );
      const data = await req.json();
      setIsRegisterLoading(false);
      if (req.status === 200) {
        setChangePasswordError(false);
        toast.success("Successfully changed password");
      } else {
        resetCaptcha();
        setChangePasswordError(data.Message);
      }
    } catch (error) {
      resetCaptcha();
      setChangePasswordError("An unknown error occured.");
    } finally {
      setIsChangePasswordLoading(false);
    }
  };

  const resetPassword = async (email, captchaToken) => {
    try {
      setIsResetPasswordLoading(true);
      setResetPasswordError(false);

      if (!email) {
        setResetPasswordError("Please enter an email.");
        return;
      }

      if (!isValidEmail(email)) {
        setResetPasswordError("That email is not valid.");
        return;
      }

      if (!captchaToken) {
        setResetPasswordError("Please complete the captcha.");
        return;
      }

      const res = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/reset-password?email=` +
          email,
        {
          headers: {
            "cf-turnstile-response": captchaToken,
          },
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        setResetPasswordMessage(
          "If an account under this email exists, they will recieve a link to reset their password."
        );
      } else {
        resetCaptcha();
        setResetPasswordError(data.Message);
      }
    } catch (error) {
      resetCaptcha();
      setResetPasswordError("Unknown error occured.");
    } finally {
      setIsResetPasswordLoading(false);
    }
  };

  const finalResetPassword = async (
    token,
    newPassword,
    confirmNewPassword,
    captchaToken
  ) => {
    try {
      setFinalResetPasswordLoading(true);
      setFinalResetPasswordError(false);

      if (!newPassword || !confirmNewPassword) {
        setResetPasswordError("Please enter an email.");
        return;
      }

      if (newPassword !== confirmNewPassword) {
        setFinalResetPasswordError("Passwords do not match.");
        return;
      }

      if (!captchaToken) {
        setFinalResetPasswordError("Please complete the captcha.");
        return;
      }

      const res = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/reset-password?email=`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "cf-turnstile-response": captchaToken,
          },
          body: JSON.stringify({
            token,
            newPassword,
            confirmNewPassword,
          }),
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        setFinalResetPasswordMessage("Your password has been reset.");
        toast.success("Your password has been reset.");
        setTimeout(() => {
          setAuthModalOpen();
        }, 1000);
      } else {
        resetCaptcha();
        setFinalResetPasswordError(data.Message);
      }
    } catch (error) {
      resetCaptcha();
      setFinalResetPasswordError("Unknown error occured.");
    } finally {
      setFinalResetPasswordLoading(false);
    }
  };

  const logout = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/logout`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        toast.success("Logged out");
        validateLogin(true);
      } else {
        toast.error(data.Message);
      }
    } catch (error) {
      toast.error("Error during logout");
    }
  };

  const validateLogin = async (redirect) => {
    setValidateLoginLoading(true);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/validate-login`,
        {
          credentials: "include",
        }
      );
      if (req.status === 200) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
        if (redirect) navigate("/");
      }
    } catch (error) {
      setIsSignedIn(false);
    } finally {
      setValidateLoginLoading(false);
    }
  };

  const clearAllAuthErrors = () => {
    setRegisterError(false);
    setLoginError(false);
  };

  const context = {
    login,
    isLoginLoading,
    loginError,

    register,
    isRegisterLoading,
    registerError,
    registerMessage,

    logout,

    changePassword,
    isChangePasswordLoading,
    changePasswordError,

    resetPassword,
    isResetPasswordLoading,
    resetPasswordError,
    resetPasswordMessage,

    finalResetPassword,
    finalResetPasswordMessage,
    finalResetPasswordError,
    finalResetPasswordLoading,

    validateLogin,
    validateLoginLoading,

    isSignedIn: isSignedIn,
    clearAllAuthErrors,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
