import { useState } from "react";
import useSWR from "swr";

// SWR fetcher
const stockFetcher = async (...args) => {
  const req = await fetch(...args, { credentials: "include" });
  if (!req.ok || req.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.status = req.status;
    throw error;
  }
  return req.json();
};

export default function useStock() {
  const [stock, setStock] = useState();
  const [lastUpdated, setLastUpdated] = useState();
  const { error, isLoading } = useSWR(
    `${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/stock`,
    stockFetcher,
    {
      onError: () => {
        setStock(null);
      },
      onSuccess: (data) => {
        const { Data } = data;
        setStock(Data.stock);
        const lastUpdated = new Date(Data.lastUpdated).toLocaleString(
          undefined,
          {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }
        );
        setLastUpdated(lastUpdated);
      },
    }
  );

  return {
    stock: stock && stock.toLocaleString(),
    stockLastUpdated: lastUpdated,
    isStockLoading: isLoading,
    isStockError: error,
  };
}
