import { useAuth } from "../context/authContext";
import { useNavigate } from "react-router-dom";

export function withAuth(Component) {
  return function WithAuth(props) {
    const { isSignedIn, validateLoginLoading } = useAuth();
    const navigate = useNavigate();

    // Redirect to login page if not authenticated
    if (!validateLoginLoading && !isSignedIn) {
      navigate("/");
      return null;
    }

    return <Component {...props} />;
  };
}
