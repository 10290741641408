import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

const failureReasons = {
  1: {
    message: "This verification token does not exist.",
    explanation: "This token may already have been used.",
    solution:
      "Try logging into your account if you have already clicked the verification link.",
  },
};

export default function VerifyFailed() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [message] = useState(() => {
    let err = Number(searchParams.get("error"));
    if (isNaN(err)) {
      setError(true);
      setLoading(false);
      return null;
    }
    if (!(err in failureReasons)) {
      setError(true);
      setLoading(false);
      return null;
    }
    setLoading(false);
    return failureReasons[err];
  });

  if (loading) {
    return <h1 className="text-white text-3xl tracking-wider pb-8">Loading</h1>;
  }

  return (
    <div className="text-center">
      {error ? (
        <h1 className="text-white text-3xl tracking-wider pb-8">Error</h1>
      ) : (
        <div>
          <h1 className="text-white text-3xl tracking-wider pb-8">
            {message.message}
          </h1>
          <p className="text-xl">Why?</p>
          <p className="pb-8 text-white/75">{message.explanation}</p>
          <p className="text-xl">What can I do?</p>
          <p className="text-white/75">{message.solution}</p>
        </div>
      )}
    </div>
  );
}
