import { motion } from "framer-motion";
import Form from "../components/buyform/Form";
import { withAuth } from "../utils/withAuth";

const Buy = () => {
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="container mx-auto"
    >
      <h1>
        buy <span className="text-main">robux</span>
      </h1>
      <div>
        <Form />
      </div>
    </motion.div>
  );
};

export default withAuth(Buy);
