import { Route, Routes, useLocation } from "react-router-dom";

// -- components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

// -- pages
import Home from "./pages/Home";
import Account from "./pages/Account";
import VerifyFailed from "./pages/VerifyFailed";
import NotFound from "./pages/NotFound";
import Buy from "./pages/Buy";
import OrderDetails from "./pages/OrderDetails";

// -- other
import ScrollToTop from "./components/ScrollTop";
import { AnimatePresence } from "framer-motion";
import Faq from "./components/Faq";
import { Toaster } from "react-hot-toast";
import NextTopLoader from "nextjs-toploader";
import { useAuth } from "./context/authContext";
import AuthModal from "./components/Auth/Modal";
import { SkeletonTheme } from "react-loading-skeleton";
import { OrderContextProvider } from "./context/orderContext";
import ResetPassword from "./components/Auth/ResetPassword";
import { useEffect } from "react";

const App = () => {
  const location = useLocation();
  const { validateLogin } = useAuth();

  useEffect(() => {
    // on every location change, validateLogin runs
    validateLogin(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <SkeletonTheme baseColor="#313131" highlightColor="#525252">
        <OrderContextProvider>
          <ScrollToTop />
          <Navbar />
          <AuthModal />
          <AnimatePresence mode="wait">
            <section className="pt-20">
              <Routes location={location} key={location.pathname}>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Home />} />
                <Route path="/buy" element={<Buy />} />
                <Route path="/account" element={<Account />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/verify-failed" element={<VerifyFailed />} />
                <Route path="/order-details" element={<OrderDetails />} />
                <Route path="/reset-password" element={<ResetPassword />} />
              </Routes>
            </section>
          </AnimatePresence>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            toastOptions={{
              style: {
                border: "1px solid #30DA30",
                padding: "16px",
                color: "#30DA30",
                background: "#212121",
              },
              success: {
                iconTheme: {
                  primary: "#30DA30",
                  secondary: "#FFFAEE",
                },
              },
            }}
          />
          <NextTopLoader
            color="#30DA30"
            showSpinner={false}
            shadow="0 0 10px #30DA30,0 0 5px #30DA30"
          />
          <Footer />
        </OrderContextProvider>
      </SkeletonTheme>
    </>
  );
};

export default App;
