import Backdrop from "../Backdrop";
import { AnimatePresence, motion } from "framer-motion";

import authGFXImage from "../../assets/authGFX.png";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";
import { useAuthModal } from "../../context/authModalContext";
import { useAuth } from "../../context/authContext";
import { useCaptcha } from "../../context/captchaContext";

const dropInOut = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const AuthModal = () => {
  const {
    setAuthModalClose,
    isAuthModalOpen,
    toggleState,
    setToggleState,
    toggleForgotPassTab,
  } = useAuthModal();
  const { clearAllAuthErrors } = useAuth();
  const { clearCaptchaErrorsAndToken } = useCaptcha();

  return (
    <AnimatePresence mode="wait">
      {isAuthModalOpen && (
        <Backdrop
          onClick={() => {
            clearAllAuthErrors();
            clearCaptchaErrorsAndToken();
            setAuthModalClose();
          }}
        >
          <motion.div
            className="relative flex h-auto"
            onClick={(e) => e.stopPropagation()}
            variants={dropInOut}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="bg-dark-100 hidden md:block">
              <img src={authGFXImage} alt="gfx" className="h-full relative" />
            </div>
            <div className="modal !px-12 relative !rounded-none">
              <div
                onClick={setAuthModalClose}
                className="cursor-pointer hover:text-white w-7 h-7 flex items-center justify-center absolute top-5 right-5"
              >
                <i className="fal fa-times"></i>
              </div>
              <h1 className="title mb-3 md:mb-8 text-2xl md:text-3xl tracking-wider text-center">
                Welcome to CheapestRobux!
              </h1>

              {toggleState === 3 ? (
                <button
                  onClick={() => {
                    clearAllAuthErrors();
                    clearCaptchaErrorsAndToken();
                    setToggleState(1);
                  }}
                  className="text-xl flex gap-2 py-2 hover:text-white/75 duration-200"
                >
                  <i class="fas fa-arrow-left text-xl"></i>
                  <span>Back</span>
                </button>
              ) : (
                <div className="w-full flex relative justify-center border-b border-dark-100">
                  <div className="bg-gradient-to-b from-dark-100 to-main h-[4px] absolute bottom-0"></div>
                  <button
                    className={`px-4 pb-3 md:text-xl duration-100 cursor-pointer font-medium w-fit z-10 whitespace-nowrap ${
                      toggleState === 1
                        ? "border-b-2 border-main text-main font-semibold"
                        : ""
                    }`}
                    onClick={() => {
                      clearAllAuthErrors();
                      clearCaptchaErrorsAndToken();
                      setToggleState(1);
                    }}
                  >
                    Login
                  </button>
                  <button
                    className={`px-4 pb-3 md:text-xl duration-100 cursor-pointer font-medium w-fit z-10 whitespace-nowrap ${
                      toggleState === 2
                        ? "border-b-2 border-main text-main font-semibold"
                        : ""
                    }`}
                    onClick={() => {
                      clearAllAuthErrors();
                      clearCaptchaErrorsAndToken();
                      setToggleState(2);
                    }}
                  >
                    Register
                  </button>
                </div>
              )}

              <div className="mt-4">
                <AnimatePresence mode="wait">
                  {toggleState === 1 && (
                    <motion.div
                      key="login"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.1 } }}
                      exit={{ opacity: 0, transition: { duration: 0.1 } }}
                    >
                      <Login toggleForgotPassTab={toggleForgotPassTab} />
                    </motion.div>
                  )}
                  {toggleState === 2 && (
                    <motion.div
                      key="register"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.1 } }}
                      exit={{ opacity: 0, transition: { duration: 0.1 } }}
                    >
                      <Register />
                    </motion.div>
                  )}
                  {toggleState === 3 && (
                    <motion.div
                      key="forgotPassword"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.1 } }}
                      exit={{ opacity: 0, transition: { duration: 0.1 } }}
                    >
                      <ForgotPassword />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </motion.div>
        </Backdrop>
      )}
    </AnimatePresence>
  );
};

export default AuthModal;
