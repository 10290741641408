import React, { useState } from "react";
import { useCaptcha } from "../../context/captchaContext";
import { useAuth } from "../../context/authContext";

export default function ForgotPassword() {
  const [email, setEmail] = useState();
  const { getTurnstileComponent, captchaToken, isCaptchaValid, captchaError } =
    useCaptcha();
  const {
    resetPassword,
    isResetPasswordLoading,
    resetPasswordError,
    resetPasswordMessage,
  } = useAuth();

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  async function sendPasswordReset() {
    await resetPassword(email, captchaToken);
  }

  return (
    <div>
      <h2 className="text-xl pb-2">Forgot your password?</h2>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col">
          <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
            <span className="text-red-500">*</span> E-mail
          </label>
          <input
            onChange={onEmailChange}
            type="text"
            id="Email"
            className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-base md:text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
          />
        </div>
        {getTurnstileComponent()}
        <button
          type="button"
          className="cta"
          onClick={sendPasswordReset}
          disabled={!(isCaptchaValid && email)}
        >
          Send reset password link
        </button>
        {resetPasswordMessage && (
          <div className="flex w-[325px] md:w-[500px]">
            <p className="text-main text-sm lg:text-base">
              {resetPasswordMessage}
            </p>
          </div>
        )}
        {isResetPasswordLoading && (
          <p className="text-white text-sm lg:text-base">Loading...</p>
        )}
        {resetPasswordError && (
          <div className="flex w-[325px] md:w-[500px]">
            <p className="text-red-500 text-sm lg:text-base">
              {resetPasswordError}
            </p>
          </div>
        )}
        {captchaError && (
          <p className="text-red-500 text-sm lg:text-base">{captchaError}</p>
        )}
      </div>
    </div>
  );
}
