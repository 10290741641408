import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { AuthContextProvider } from "./context/authContext";
import { CaptchaContextProvider } from "./context/captchaContext";
import { AuthModalContextProvider } from "./context/authModalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <CaptchaContextProvider>
        <AuthModalContextProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </AuthModalContextProvider>
      </CaptchaContextProvider>
    </React.StrictMode>
  </BrowserRouter>
);
