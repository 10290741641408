import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { useAuthModal } from "../context/authModalContext";
import { useAuth } from "../context/authContext";

const Navbar = () => {
  const { isSignedIn } = useAuth();
  const { setAuthModalOpen } = useAuthModal();

  return (
    <header>
      {/* Desktop */}
      <nav className="hidden md:flex">
        <div className="container mx-auto flex items-center justify-between">
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="Logo" />
            </div>
          </Link>
          <ul className="flex gap-3 lg:gap-6 duration-300 ml-4">
            <li>
              <Link to="/" className="link hover:text-white/75 duration-200">
                Home
              </Link>
            </li>
            <li className="px-[1px] text-white/25">|</li>
            <li className="flex items-center gap-2 link hover:text-white/75 duration-200">
              <i className="fab fa-discord"></i>
              <a
                href={`${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/discord`}
                target="_blank"
                rel="noreferrer"
              >
                Discord
              </a>
            </li>
            <li className="flex justify-center">
              {isSignedIn ? (
                <Link className="cta tracking-wide whitespace-nowrap" to="/buy">
                  Buy Robux
                </Link>
              ) : (
                <button
                  className="cta tracking-wide whitespace-nowrap"
                  onClick={setAuthModalOpen}
                >
                  Login / Register
                </button>
              )}
            </li>
            <li>
              <Link to="/faq" className="link hover:text-white/75 duration-200">
                FAQ
              </Link>
            </li>
            {isSignedIn && (
              <>
                <li className="px-[1px] text-white/25">|</li>
                <li>
                  <Link to="/account" className="link">
                    <i className="fas text-3xl fa-user-circle hover:text-white/75 duration-200"></i>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
      {/* Mobile */}
      <div className="flex md:hidden flex-col w-full bg-dark border-white/10 fixed top-0 z-50 border-b p-4 items-center">
        <Link to="/">
          <div className="logo">
            <img src={logo} alt="Logo" width={200} />
          </div>
        </Link>
      </div>
      {/* Mobile */}
      <div
        className={`flex md:hidden w-full py-2 bg-dark-100 border-t border-white/10 fixed bottom-0 z-50 border-b items-center ${!isSignedIn && "px-5"}`}
      >
        <Link
          to="/"
          className="flex flex-col items-center justify-center w-full"
        >
          <i className="fas fa-home text-lg"></i>
          <span className="uppercase text-xs font-semibold tracking-wider">
            home
          </span>
        </Link>
        {isSignedIn ? (
          <Link
            to="/buy"
            className="flex w-full h-14 flex-col text-dark items-center justify-center bg-main rounded-full"
          >
            <i className="fas fa-home text-lg"></i>
            <span className="uppercase text-xs font-semibold">buy</span>
          </Link>
        ) : (
          <button
            onClick={setAuthModalOpen}
            className="flex w-full !px-3 !py-4 flex-col text-dark items-center justify-center bg-main rounded-2xl"
          >
            <span className="uppercase text-[15px] font-semibold">
              Login / Register
            </span>
          </button>
        )}

        {isSignedIn && (
          <Link
            to="/account"
            className="flex flex-col items-center justify-center w-full "
          >
            <i className="fas fa-user text-lg"></i>
            <span className="uppercase text-xs font-semibold tracking-wide">
              profile
            </span>
          </Link>
        )}
      </div>
    </header>
  );
};

export default Navbar;
