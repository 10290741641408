import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import ChangePassword from "../Auth/ChangePassword";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AccountSettings = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        setIsLoading(true);
        setError(false);

        const req = await fetch(
          `${process.env.REACT_APP_MAIN_PROTOCOL}://user.${process.env.REACT_APP_MAIN_DOMAIN}/data`,
          {
            credentials: "include",
          }
        );

        setIsLoading(false);

        if (!req.ok) {
          setError(true);
          return;
        }

        const data = await req.json();
        setEmail(data["Data"].email);
      } catch (error) {
        setError(true);
        setIsLoading(false);
      }
    };

    fetchEmail();
  }, []);

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      <h2 className="text-4xl font-semibold">Account Settings</h2>
      <div className="mt-10 grid md:grid-cols-2">
        <div className="flex flex-col gap-5 col-span-2 lg:col-span-1">
          <div className="flex items-center justify-between text-xl">
            <div>
              <h3 className="opacity-50 text-base">Email</h3>
              {isLoading ? (
                <Skeleton width={150} />
              ) : (
                <h3 className="font-medium">{email}</h3>
              )}
              {error && (
                <h3 className="font-medium text-red-500">
                  Error getting email
                </h3>
              )}
              <p className="font-normal pt-4">
                To request an email change, please open a ticket in the{" "}
                <a
                  href={`${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/discord`}
                  target="_blank"
                  rel="noreferrer"
                  className="redirect"
                >
                  discord
                </a>
              </p>
            </div>
          </div>
          <button
            className="cta !bg-dark-100 hover:!bg-dark-100/50 text-xl mt-5"
            onClick={() => (modalOpen ? close() : open())}
          >
            Change Password
          </button>
        </div>
      </div>
      <AnimatePresence initial={false} mode="wait">
        {modalOpen && (
          <ChangePassword modalOpen={modalOpen} handleClose={close} />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default AccountSettings;
