import { useState } from "react";
import Backdrop from "../Backdrop";
import { motion } from "framer-motion";
import { useAuth } from "../../context/authContext";
import { useCaptcha } from "../../context/captchaContext";

const dropInOut = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const ChangePassword = ({ handleClose }) => {
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [showPasswords, setShowPasswords] = useState(false);
  const { changePassword, isChangePasswordLoading, changePasswordError } =
    useAuth();
  const { getTurnstileComponent, captchaToken, isCaptchaValid } = useCaptcha();

  const handleClickShowPassword = () => setShowPasswords((state) => !state);

  const updateNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const updateConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const sendChangePassword = async () => {
    await changePassword(newPassword, confirmNewPassword, captchaToken);
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="modal max-w-[500px]"
        onClick={(e) => e.stopPropagation()}
        variants={dropInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex justify-between">
          <div className="flex gap-2 items-center mb-1">
            <h3 className="text-xl font-semibold">Change Password</h3>
          </div>
          <div
            onClick={handleClose}
            className="cursor-pointer hover:text-white w-7 h-7 flex items-center justify-center"
          >
            <i className="fal fa-times text-xl"></i>
          </div>
        </div>
        <div className="mt-5 flex flex-col gap-3">
          <div className="flex flex-col gap-5">
            <input
              onChange={(e) => updateNewPassword(e)}
              type={showPasswords ? "text" : "password"}
              placeholder="New Password"
              className="w-full"
            />
            <input
              onChange={(e) => updateConfirmNewPassword(e)}
              type={showPasswords ? "text" : "password"}
              placeholder="Confirm Password"
              className="w-full"
            />
          </div>
          <div className="flex justify-items-center">
            <button
              onClick={handleClickShowPassword}
              className="text-white hover:text-white/75 duration-200"
            >
              {showPasswords ? "Hide passwords" : "Show passwords"}
            </button>
          </div>

          {/* Captcha */}
          <div className="py-4 flex justify-center">
            {getTurnstileComponent()}
          </div>

          <div className="flex flex-col md:flex-row w-full text-lg items-center font-medium gap-2 justify-end">
            <button
              className="cta-cancel !py-2 !w-full md:!w-fit !bg-dark"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              disabled={!isCaptchaValid}
              type="button"
              onClick={sendChangePassword}
              className="cta !py-2 !w-full md:!w-fit"
            >
              Save Changes
            </button>
          </div>
        </div>
        {isChangePasswordLoading && (
          <p className="text-sm md:text-lg text-end">Loading...</p>
        )}
        {changePasswordError && (
          <p className="text-red-500 text-sm md:text-lg text-end">
            {changePasswordError}
          </p>
        )}
      </motion.div>
    </Backdrop>
  );
};

export default ChangePassword;
