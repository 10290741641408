import { motion } from "framer-motion";

function FundsPendWarning({ handleNext, handleBack, robuxAmount }) {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <form>
        <h2 className="text-3xl md:text-4xl font-semibold md:pb-8 pb-4 text-center md:text-start">
          ⚠️ Warning ⚠️
        </h2>

        <h2 className="text-xl md:text-2xl font-medium underline underline-offset-2 pb-2">
          Funds may take 3 to 7 days to pend
        </h2>
        <p className="font-normal text-base md:text-xl text-white/75 mb-5">
          These funds are transferred via a Gamepass purchase and it will take 3
          to 7 days to reach your account!<br></br>But, you can check your{" "}
          <a
            href="https://www.roblox.com/transactions"
            target="_blank"
            rel="noreferrer"
            className="underline text-main hover:cursor-pointer hover:text-main/75 duration-100"
          >
            pending robux
          </a>{" "}
          and see it as soon as your order is confirmed!
        </p>

        <h2 className="text-xl md:text-2xl font-medium mt-8 underline underline-offset-2 pb-2">
          Roblox has a 30% sale tax
        </h2>
        <p className="font-normal text-base md:text-xl text-white/75 mb-5">
          Due to the robux being transferred to your account via a gamepass,
          Roblox will take 30%<br></br>This means{" "}
          <span className="underline underline-offset-4">
            <span className="text-white">
              you will recieve {(robuxAmount * 0.7).toLocaleString()} R$
            </span>{" "}
            <span className="text-main">(70%)</span>{" "}
            <span className="text-white">
              and not {robuxAmount.toLocaleString()} R$
            </span>
          </span>
        </p>

        <div className="flex gap-4 justify-center md:justify-end whitespace-nowrap">
          <button
            type="button"
            className="cta-cancel !w-auto !py-2 !px-4 md:!px-7 text-xl text-center"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="submit"
            className="cta !w-auto !py-2 !px-4 md:!px-7 text-xl text-center"
            onClick={handleNext}
          >
            I understand
          </button>
        </div>
      </form>
    </motion.div>
  );
}

export default FundsPendWarning;
