import { createContext, useContext, useState } from "react";

const AuthContext = createContext({ user: null });

export const AuthModalContextProvider = ({ children }) => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [toggleState, setToggleState] = useState(1);

  const setAuthModalOpen = () => setIsAuthModalOpen(true);
  const setAuthModalClose = () => {
    setToggleState(1);
    setIsAuthModalOpen(false);
  };
  const toggleForgotPassTab = () => setToggleState(3);

  const context = {
    setAuthModalOpen,
    setAuthModalClose,
    toggleState,
    setToggleState,
    toggleForgotPassTab,
    isAuthModalOpen,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const useAuthModal = () => useContext(AuthContext);
