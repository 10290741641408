import React, { useState } from "react";
import { useAuth } from "../../context/authContext";
import { useCaptcha } from "../../context/captchaContext";

export default function Login({ toggleForgotPassTab }) {
  const [loginData, setLoginData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { login, isLoginLoading, loginError } = useAuth();
  const { captchaToken, isCaptchaValid, captchaError, getTurnstileComponent } =
    useCaptcha();

  async function sendLogin() {
    await login(loginData.email, loginData.password, captchaToken);
  }

  return (
    <div className="flex flex-col md:gap-4 gap-2">
      <div className="flex flex-col">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> E-mail
        </label>
        <input
          type="text"
          onChange={(e) =>
            setLoginData({ ...loginData, email: e.target.value })
          }
          className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-base md:text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
        />
      </div>
      <div className="flex flex-col pb-2">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> Password
        </label>
        <div className="flex flex-col gap-1">
          <input
            type={showPassword ? "text" : "password"}
            onChange={(e) =>
              setLoginData({ ...loginData, password: e.target.value })
            }
            className="!bg-dark rounded-md w-full !border-none placeholder-white/50 border-white/5 text-base md:text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
          />
          <div className="flex">
            <button
              onClick={() => setShowPassword((state) => !state)}
              className="text-white hover:text-white/75 duration-200 pl-2"
            >
              {showPassword ? "Hide password" : "Show password"}
            </button>
          </div>
        </div>
      </div>
      {/* Captcha */}
      {getTurnstileComponent()}
      <button
        disabled={!(isCaptchaValid && loginData.email && loginData.password)}
        className="cta text-lg"
        onClick={sendLogin}
      >
        Login
      </button>
      {isLoginLoading && (
        <div>
          <p className="text-white text-sm lg:text-base">Loading...</p>
        </div>
      )}
      {loginError && (
        <div>
          <p className="text-red-500 text-sm lg:text-base">{loginError}</p>
        </div>
      )}
      {captchaError && (
        <div>
          <p className="text-red-500 text-sm lg:text-base">{captchaError}</p>
        </div>
      )}
      <div className="flex">
        <button
          onClick={toggleForgotPassTab}
          className="text-end text-main/75 underline underline-offset-2"
        >
          Forgot your password?
        </button>
      </div>
    </div>
  );
}
