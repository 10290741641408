const popularDomains = new Set([
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "zoho.com",
  "yandex.com",
  "protonmail.com",
  "live.com",
  "msn.com",
  "comcast.net",
  "me.com",
  "mac.com",
  "sbcglobal.net",
  "verizon.net",
  "att.net",
  "bellsouth.net",
  "charter.net",
  "earthlink.net",
  "hubspot.com",
  "mail.ru",
  "inbox.com",
  "rocketmail.com",
  "yahoo.co.uk",
  "btinternet.com",
  "sky.com",
  "virginmedia.com",
  "ntlworld.com",
  "talktalk.net",
  "tiscali.co.uk",
  "blueyonder.co.uk",
  "btconnect.com",
  "eircom.net",
  "eir.ie",
  "mail.bg",
  "abv.bg",
  "ukr.net",
  "email.com",
  "mailinator.com",
]);

function isEmailFromUnpopularDomain(email) {
  const emailParts = email.split("@");
  if (!isValidEmail(email)) {
    return false;
  }

  const domain = emailParts[1];
  return !popularDomains.has(domain);
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function validateRegistrationDetails(email, password, confirmPassword) {
  if (!email || !password || !confirmPassword)
    return {
      result: false,
      message: "You have not fully entered your registration information.",
    };

  // password !== confirmPassword
  if (password !== confirmPassword)
    return {
      result: false,
      message: "The passwords you entered do not match. Please try again.",
    };

  // password.length >= 6
  if (password.length < 6)
    return {
      result: false,
      message: "Your password must be at least 6 characters in length.",
    };

  // Email checks
  if (!isValidEmail(email))
    return { result: false, message: "E-mail is not valid." };

  if (isEmailFromUnpopularDomain(email))
    return { result: false, message: "Your e-mail provider is not allowed." };

  return { result: true };
}
